.red {
  color: rgb(220, 0, 78)!important;
}
.error {
  color: #f44336!important;
}
.a-span {
  color: #0486c3;
}
.txt-center {
  text-align: center;
}
.note-1 {
  color: #6d6d6d;
  font-size: 90%;
}
.color-success {
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
}
i.color-success {
  color: #4caf50!important;
}
.color-danger {
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
}
i.color-danger {
  color: #f44336!important;
}
.danger {
  color: #f44336;
  background-color: #F2DEDE;
}
.pre-line {
  white-space: pre-line;
}
.trim {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

/**
 * Login
 */
.login-h {
  text-align: center;
}
.password-reset-a {
  cursor: pointer;
}
#password-reset-sended {
  .prs-p1 {
    margin-bottom: 15px;
  }
}

/**
 * Roles
 */
.role-key-name {
  font-weight: bold;
}
.role-action-li {
  margin-left: 10px;
  &:last-child {
    margin-bottom: 20px;
  }
}

/**
 * ZshEditor
 */
.zsh-editor {
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  background: white;
  padding: 5px;
}

/**
 * MailMagazineGroup
 */
.mail-magazine-groups-tab-wr {
  margin-top: 40px;
  .data-table .data-table-head-info {
    margin-top: 0;
  }
  .filter-groups-inline {
    button {
      margin: 15px 0;
    }
  }
}

/**
 * AttributeGroups
 */
.tree-folder-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#attribute-groups {
  .attribute-groups-first-parent-add-wr {
    display: flex;
    align-items: center;
    padding: 15px 0;
    font-size: 1rem;
    line-height: normal;
    cursor: pointer;
    span:first-child {
      padding-right: 5px;
      padding-top: 3px;
    }
  }
}
.attribute-group-conditions {
  padding: 0;
  padding-left: 15px;
  margin: 0;
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .a-span {
      text-decoration: none;
      cursor: pointer;
      max-width: 300px;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      margin-right: 7px;
    }
  }
}

/**
 * AttributeGroupConditions
 */
.attribute-group-condition-selects {
  .agcs-head-btn-wr {
    padding: 15px 0;
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .agc-index {
    width: 5%;
  }
  .agc-inputs {
    width: 90%;
    .agc-input-control {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .agc-delete {
    button {
      float: right;
    }
  }
}
.attribute-group-condition-or {
  .agcs-head-btn-wr {
    padding-top: 0;
  }
}
.agc-or-conditions-view {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
  }
  .agc-or-conditions-view-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
    width: 60%;
  }
}
.agcp-btn-wr {
  padding-bottom: 15px;
}
@media screen and (max-width:768px) {
  .attribute-group-condition-selects {
    .agc-inputs {
      .agc-input-control {
        display: block;
        margin-right: 0px;
      }
    }
  }
  .agc-or-conditions-view {
    display: block;
    overflow: hidden;
    width: 100px;
    span {
      display: block;
    }
  }
  .attribute-group-condition-preview {
    .MuiTableRow-head {
      display: none;
    }
  }
}

/**
 * ShMailLogs
 */
.sh-mail-log-api-content {
  display: flex;
  padding-top: 5px;
  .smlac-title {
    padding-right: 5px;
    white-space: nowrap;
  }
}
@media screen and (max-width:768px) {
  .sh-mail-log-api-content {
    display: block;
  }
}